import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { SigninComponent } from './signin/signin.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SummaryComponent } from './summary/summary.component';
import { AssetComponent } from './asset/asset.component';
import { TableComponent } from './table/table.component';
import { AssetsComponent } from './assets/assets.component';
import { UsersComponent } from './users/users.component';
import { InventoryComponent } from './inventory/inventory.component';
import { AddAssetComponent } from './add-asset/add-asset.component';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { IntervalFieldComponent } from './interval-field/interval-field.component';
import { SignupService } from './services/signup.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SigninService } from './services/signin.service';
import { SessionService } from './services/session.service';
import { LoginGuard } from './guards/login.guard';
import { SessionExpiredInterceptor } from './interceptors/session-expired.interceptor';
import { AddTokenInterceptor } from './interceptors/add-token.interceptor';
import { ActivateChildGuard } from './guards/activate-child.guard';
import { SignoutService } from './services/signout.service';
import { UsersService } from './services/users.service';
import { InventoryService } from './services/inventory.service';
import { AssetsService } from './services/assets.service';
import { AnalyticsService } from './services/analytics.service';
import { DeviceSetupComponent } from './device-setup/device-setup.component';
import { NgxEchartsModule } from 'ngx-echarts';

import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';

import * as echarts from 'echarts/core';

// Import bar charts, all with Chart suffix
import { BarChart } from 'echarts/charts';
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
} from 'echarts/components';
// Import the Canvas renderer, note that introducing the CanvasRenderer or SVGRenderer is a required step
import { CanvasRenderer } from 'echarts/renderers';
import 'echarts/theme/macarons.js';
import { SharedModule } from './shared/shared.module';
import { DeviceOverviewComponent } from './device-overview/device-overview.component';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { ImoleLogoHorizontalComponent } from './imole-logo-horizontal/imole-logo-horizontal.component';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
]);

const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: 'vernemq.imole.com.ng',
  port: 8084,
  protocol: 'wss',
  path: '/mqtt',
  username: '',
  password: '',
};

@NgModule({
  declarations: [
    AppComponent,
    OnboardingComponent,
    SigninComponent,
    SignupComponent,
    DashboardComponent,
    SummaryComponent,
    AssetComponent,
    TableComponent,
    AssetsComponent,
    UsersComponent,
    InventoryComponent,
    AddAssetComponent,
    AddInventoryComponent,
    IntervalFieldComponent,
    DeviceSetupComponent,
    DeviceOverviewComponent,
    ImoleLogoHorizontalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgxEchartsModule.forRoot({ echarts }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    SharedModule,
  ],
  providers: [
    SignupService,
    SigninService,
    SessionService,
    SignoutService,
    LoginGuard,
    ActivateChildGuard,
    UsersService,
    InventoryService,
    AssetsService,
    AnalyticsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SessionExpiredInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
