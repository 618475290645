<div class="d-flex flex-column py-sm-3">
    <!-- INFO Add new device Button -->
    <div class="d-flex w-100 mb-3">
        <a mat-flat-button [routerLink]="['/', 'board', 'devices', 'setup']"
            class="h-fit-content ms-auto rounded-3 text-white" color="primary">
            <mat-icon class="my-auto">add</mat-icon>
            <span class="my-auto">Add new device</span>
        </a>
    </div>

    <app-interval-field class="ms-auto w-100 w-sm-50 w-md-75 w-lg-40 mt-3"
        (intervalFieldChange)="intervalChange($event)"></app-interval-field>

    <!-- INFO Stats -->
    <div class="d-flex w-100 my-3" style="overflow-x: auto;">
        <mat-card
            class="d-flex flex-column col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 me-1 p-2 p-sm-3 rounded-3 shadow-none">
            <span class="mat-caption fw-bold">Total Energy Consumed</span>

            <div class="d-flex w-100">
                <span class="ms-auto mb-0 mat-headline-3">{{ (sumTotalOfEnergy >= 1000 ? sumTotalOfEnergy / 1000 :
                    sumTotalOfEnergy) | number:'1.0-2' }}</span>
                <span class="mb-auto mat-caption">{{ sumTotalOfEnergy >= 1000 ? 'kWh' : 'Wh' }}</span>
            </div>
        </mat-card>

        <mat-card
            class="d-flex flex-column col-11 col-sm-7 col-md-6 col-lg-5 col-xl-4 ms-1 p-2 p-sm-3 rounded-3 shadow-none">
            <span class="mat-caption fw-bold">Total Power</span>

            <div class="d-flex w-100">
                <span class="ms-auto mb-0 mat-headline-3">{{ sumTotalOfPower | number:'1.0-2' }}</span>
                <span class="mb-auto mat-caption">W</span>
            </div>
        </mat-card>
    </div>

    <!-- INFO Analytics -->
    <div class="d-flex flex-wrap flex-wrap-reverse mt-3">
        <!-- INFO Overview trend -->
        <div class="col-12 col-md-7 pe-md-1">
            <mat-card class="d-flex flex-column mb-3 p-0 shadow-none rounded-3" [style]="{height: '372px'}">
                <mat-progress-bar *ngIf="overviewDataLoaded === false" class="w-100" mode="query"
                    color="warn"></mat-progress-bar>

                <!-- INFO INFO Caption Text-->
                <div class="d-flex px-2">
                    <caption class="fw-bolder mat-caption text-gray">Trend</caption>
                </div>

                <app-bar-chart class="d-flex w-100 h-100" [series]="seriesData"></app-bar-chart>
            </mat-card>
        </div>

        <!-- INFO Add top four consumption -->
        <div class="col-12 col-md-5 ps-md-1">
            <mat-card class="d-flex flex-column mb-3 p-0 shadow-sm rounded-3" [style]="{height: '372px'}">
                <mat-progress-bar *ngIf="topFourDataLoaded === false" class="w-100" mode="query"
                    color="warn"></mat-progress-bar>

                <!-- INFO INFO Caption Text-->
                <div class="d-flex px-2">
                    <caption class="fw-bolder mat-caption text-gray">Top four total device consumption</caption>
                </div>

                <app-pie-chart class="d-flex w-100 h-100" [data]="pieChartData"></app-pie-chart>
            </mat-card>
        </div>
    </div>

    <!-- INFO Latest Four devices -->
    <ng-container *ngIf="devices.length > 0; else noDevices">
        <app-device-list class="mt-5" [devices]="devices"></app-device-list>
    </ng-container>
    
    <ng-template #noDevices>
        <div class="d-flex flex-column w-100 my-5">
            <app-box-empty class="mx-auto" style="width: 256px; height: 256px;"></app-box-empty>
            <span class="mx-auto mt-3 mat-subtitle-1">No devices available... Please wait</span>
        </div>
    </ng-template>

    <a mat-stroked-button [routerLink]="['/', 'board', 'devices']" class="mx-auto mt-5 mb-3 px-5 py-4 rounded-3">See all
        devices</a>

    <!-- TODO Shortcut to invite users page -->
    <!-- <div class="d-flex flex-wrap w-100 pt-5">
        <span class="mat-h4 fw-normal">A little about me...</span>

        <mat-card class="d-flex flex-column w-100 rounded-3 shadow-none bg-transparent border border-2 border-light">
            <span class="ms-auto my-2 text-danger fw-bold">Since {{ userDetails('time') | date:'longDate' }}</span>

            <div class="d-flex mb-1">
                <span class="my-auto mat-headline">{{ userDetails('firstname') }} {{ userDetails('lastname')
                    }}
                </span>

                <mat-icon class="ms-1 my-auto material-icons-outlined" inline color="accent">verified_user</mat-icon>
            </div>
            <div class="d-flex">
                <mat-icon class="my-auto material-icons-outlined" fontIcon="outline" color="accent">alternate_email
                </mat-icon>

                <span class="ms-2 my-auto text-dark">{{ userDetails('email') }}</span>
            </div>
        </mat-card>
    </div> -->

    <!-- INFO USE FOR -->
    <!-- <ng-container *ngIf="false; else noPriviledged;">
        <span class="mt-5 mb-0 mat-headline">Users (Privileged)</span>
        <span class="mt-1 mb-0 mat-subheading-1">A few of your privileged users...</span>

        <div class="d-flex flex-wrap w-100 py-3">
            <div *ngFor="let u of [1,2,3,4,5, 6]" class="col-12 col-md-6 pe-md-1 ps-md-1">
                <mat-card matRipple class="d-flex flex-row mb-2 p-0 shadow-sm rounded-3">
                    <img class="rounded-3" src="https://randomuser.me/api/portraits/women/50.jpg" alt="user-image"
                        [style]="{width: '64px'}">
                    <div class="d-flex flex-column p-2">
                        <span class="mb-1 mat-subheading-1 fw-bold">John Doe</span>
                        <span class="text-gray">mail@mail.co.uk</span>
                    </div>
                </mat-card>
            </div>
        </div>
    </ng-container>

    <ng-template #noPriviledged>
        <span class="mt-5 mat-h2">No Priviledged Users added yet...</span>
    </ng-template> -->
</div>
