import { Component } from '@angular/core';
import { IDevice } from '../interfaces/device.interface';
import { DeviceService, IGetAggregatedData } from '../services/device.service';
import { TasmotaService } from '../services/tasmota.service';
import { FormControl, Validators } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { interval } from 'rxjs';

@Component({
  selector: 'app-device-overview',
  templateUrl: './device-overview.component.html',
  styleUrls: ['./device-overview.component.scss'],
})
export class DeviceOverviewComponent {
  public averageConsumption: any[] = [];
  public totalConsumption: any[] = [];
  public dataLoaded = false;
  public device!: IDevice;
  public editDeviceName: boolean = false;
  public deviceNameControl: FormControl = new FormControl(null, [
    Validators.required,
  ]);
  public id!: string;
  public ON_STATE: string = 'ON';
  public OFF_STATE: string = 'OFF';
  public lastSeen!: number;
  public isOnline: boolean = false;
  public sumTotalOfEnergy: number = 0;
  public sumTotalOfPower: number = 0;
  public networkInformation!: { bssid: string; ssid: string; channel: number; mode: string; rssi: number; signal: number; };
  public seriesData: any[] = [];

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly deviceService: DeviceService,
    private readonly snackbar: MatSnackBar,
    private readonly tasmotaService: TasmotaService
  ) {}

  ngOnInit() {
    this.id = (this.activatedRoute.snapshot.params as any).id;

    this.getDevice();
    this.getDeviceLastSeen();
    this.getDeviceNetworkInformation();

    interval(120000).subscribe({
      next: () => {
        this.isOnline = this.device.powered ?? false;
        this.getDeviceLastSeen();
      },
    });
  }

  public get isNowOffline() {
    const what = new Date().getTime() - this.lastSeen;
    const sixMinutesInTimestamp = 2 * 60 * 1000;

    return what >= sixMinutesInTimestamp;
  }

  public getPowerSumOverInterval(payload: IGetAggregatedData) {
    this.deviceService.getSumOverInterval({ ...payload }).subscribe({
      next: (data) => {
        if (!data[0]) return;
        this.sumTotalOfPower = data[0].value;
      },
      error: (error) => {
        console.error(`[getSumOverInterval]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getEnergySumOverInterval(payload: IGetAggregatedData) {
    this.deviceService.getSumOverInterval({ ...payload }).subscribe({
      next: (data) => {
        if (!data[0]) return;
        this.sumTotalOfEnergy = data[0].value;
      },
      error: (error) => {
        console.error(`[getEnergySumOverInterval]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public intervalChange(
    data: Pick<IGetAggregatedData, 'interval' | 'start' | 'end'>
  ) {
    const payload = {
      interval: data.interval,
      start: new Date(data.start as Date).getTime(),
      end: new Date(data.end as Date).getTime(),
      deviceId: this.device.id,
    };

    this.getAggregatedAverageData({ ...payload, key: 'energy' });
    this.getAggregatedSumTotalData({ ...payload, key: 'energy' });
    this.getPowerSumOverInterval({ ...payload, key: 'power' });
    this.getEnergySumOverInterval({ ...payload, key: 'energy' });
  }

  public getAggregatedSumTotalData(payload: IGetAggregatedData) {
    this.deviceService.getAggregatedSumTotalData(payload).subscribe({
      next: (data) => {
        this.dataLoaded = true;
        this.totalConsumption = data.map((d) => [d.time, d.value]);
        this.seriesData[1] = { type: 'bar', name: 'Total Consumption', data: this.totalConsumption };
      },
      error: (error) => {
        this.dataLoaded = true;
        console.error(`[getAggregatedSumTotalData]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getAggregatedAverageData(payload: IGetAggregatedData) {
    this.deviceService.getAggregatedAverageData(payload).subscribe({
      next: (data) => {
        this.dataLoaded = true;
        this.averageConsumption = data.map((d) => [d.time, d.value]);
        this.seriesData[0] = {
          type: 'bar',
          name: 'Average Consumption',
          data: this.averageConsumption,
        };
      },
      error: (error) => {
        this.dataLoaded = true;
        console.error(`[getAggregatedAverageData]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public listenForDevicePower(id: string) {
    this.deviceService.listenForDevicePower(id).subscribe({
      next: (message) => {
        const powerState = message.payload.toString();

        this.device.powered = powerState
          .toLowerCase()
          .includes(this.ON_STATE.toLowerCase())
          ? true
          : false;
        this.lastSeen = new Date().getTime();
      },
      error: (error) => {
        console.error(`[listenForDevicePower]`, error);
      },
    });
  }

  public listenForDeviceState(id: string) {
    this.deviceService.listenForDeviceState(id).subscribe({
      next: (message) => {
        const latestState: { POWER: string } = JSON.parse(
          message.payload.toString()
        );

        console.log(`[listenForDeviceState]`, latestState);

        this.device.powered =
          latestState.POWER === this.ON_STATE ? true : false;
      },
      error: (error) => {
        console.error(`[listenForDeviceState]`, error);
      },
    });
  }

  public get now() {
    return new Date().getTime();
  }

  public toggleDevicePowerStatus(e: MatSlideToggleChange) {
    this.tasmotaService.toggleDeviceState(this.device.id).subscribe({
      next: () => {
        this.lastSeen = new Date().getTime();
      },
      error: (error) => {
        console.error(`[toggleDevicePowerStatus]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getDeviceNetworkInformation() {
    const id = this.id;

    this.deviceService.getNetworkInformation(id).subscribe({
      next: (response) => {
        this.networkInformation = response;
      },
      error: (error) => {
        console.error(`[getDeviceLastSeen]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getDeviceLastSeen() {
    const id = this.id;

    this.deviceService.getLastSeen(id).subscribe({
      next: (response) => {
        this.lastSeen = new Date(response.time).getTime();
      },
      error: (error) => {
        console.error(`[getDeviceLastSeen]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }

  public getDevice() {
    const limit = 1;
    const offset = 0;
    const id = this.id;

    this.deviceService.listDevices(limit, offset, id).subscribe({
      next: (response) => {
        this.device = response[0];
        this.deviceNameControl.setValue(this.device.name);
        this.listenForDeviceState(this.device.id);
        this.listenForDevicePower(this.device.id);
      },
      error: (error) => {
        console.error(`[getDevice]`, error);

        this.snackbar.open('An error occurred!', 'close', {
          verticalPosition: 'top',
          horizontalPosition: 'end',
          panelClass: ['bg-danger', 'text-white'],
        });
      },
    });
  }
}
